import identity from "lodash/identity";
import type { Message, MessageDto } from "../../component";
import * as CitationMapper from "./citationMapper";

export const fromDto = (message: MessageDto): Message => {
  return {
    createdAt: message.createdAt,
    id: message.id,
    content: message.message[0].text.value,
    role: message.role,
    citations: message.citations.map(CitationMapper.fromDto).filter(identity),
  };
};
