// TODO add the correct type when BE finish a polishing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MessageDto = any;

export interface Message {
  id: string;
  content: string;
  role: "assistant" | "user";
  createdAt?: number | Date;
  promptSuggestions?: Array<{
    label: string;
    value: string;
  }>;
  citations: Citation[];
}

export type CitationType = "PDF" | "TEXT";

export interface BaseCitation {
  id: string;
  type: CitationType; // discriminator
  sourceUrl: string;
}
export interface PdfCitationHighlightAnchorCoordinate {
  x: number;
  y: number;
  pageNumber: number;
}

export interface PDFCitation extends BaseCitation {
  type: "PDF";
  name: string;
  coordinates: {
    start: PdfCitationHighlightAnchorCoordinate;
    end: PdfCitationHighlightAnchorCoordinate;
  };
}

export interface TxtCitation extends BaseCitation {
  type: "TEXT";
  name: string;
  passage: string;
}

export type Citation = PDFCitation | TxtCitation;

export interface AIChatProps {
  thread: Message[];
  threadId?: string;
  prompt: string;
  promptInputPlaceholder?: string;
  chatTitle?: string;
  chatDescription?: string;
  isWaitingForResponse?: boolean;
  onPromptChange: (prompt: string) => void;
  onSubmit?: () => void;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  onAttachFiles?: (files: FileList) => void;
  onDeleteThread?: () => void;
  isThreadLoading: boolean;
  size?: keyof typeof CHAT_SIZE;
}

export const CHAT_SIZE = {
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
  FIT_PAGE: "FIT_PAGE",
} as const;
