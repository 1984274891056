import type {
  BaseCitation,
  Citation,
  PDFCitation,
  TxtCitation,
} from "../../component";

// TODO add the correct type when BE finish a polishing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromDto = ({ citation, content }: any): Citation | null => {
  const baseCitationLink: BaseCitation = {
    // Citation from the same source has the same id.
    // Generate a random id to avoid conflicts.
    id: Math.random().toString(36).substring(7),
    type: citation.type,
    sourceUrl: citation.sourceUrl,
  };

  switch (citation.type) {
    case "PDF":
      return pdfFromDto(baseCitationLink, citation);
    case "TEXT":
      return txtFromDto(baseCitationLink, citation, content);
    default: {
      // Ignore unsupported citation types for now.
      return null;
    }
  }
};

export const pdfFromDto = (
  baseCitationLink: BaseCitation,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
): PDFCitation => {
  return {
    ...baseCitationLink,
    type: "PDF",
    coordinates: {
      start: {
        x: citation.metadata.start.x,
        y: citation.metadata.start.y,
        pageNumber: citation.metadata.start.pageNumber,
      },
      end: {
        x: citation.metadata.end.x,
        y: citation.metadata.end.y,
        pageNumber: citation.metadata.end.pageNumber,
      },
    },
    name: citation.name,
  };
};

export const txtFromDto = (
  baseCitationLink: BaseCitation,
  // TODO add the correct type when BE finish a polishing
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  citation: any,
  content: string,
): TxtCitation => {
  return {
    ...baseCitationLink,
    type: "TEXT",
    name: citation.name,
    passage: content,
  };
};
