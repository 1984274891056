import { getFileExtension, getFileName } from "@appsmith/utils";
import { Button } from "@appsmith/wds";
import truncate from "lodash/truncate";
import React from "react";
import type { Citation } from "./types";

interface ChatCitationItemProps {
  citation: Citation;
  onOpen?: (citation: Citation) => void;
}

export const ChatCitationItem = ({
  citation,
  onOpen,
}: ChatCitationItemProps) => {
  /**
   * @example
   * normalizeCitationName("example.pdf") // "example.pdf"
   * normalizeCitationName("very long file name.pdf") // "very long f...pdf"
   */
  const normalizeCitationName = (name: string): string => {
    const fileName = getFileName(name);
    const truncatedFileName = truncate(fileName, { length: 12 });
    const separator = fileName.length === truncatedFileName.length ? "." : "";

    return `${truncatedFileName}${separator}${getFileExtension(name)}`;
  };

  return (
    <Button
      color="neutral"
      icon="link"
      key={citation.id}
      onPress={() => onOpen?.(citation)}
      size="small"
      variant="subtle"
    >
      {normalizeCitationName(citation.name)}
    </Button>
  );
};
